<template>
	<div>
		<center-header
			:fetched-data="fetchedData"
			:links="links"
			dynamic
		/>

		<div v-for="(block, index) in fetchedData.blocks" :key="index">
			<component
				:is="block.type"
				:ref="block.type"
				:index="index"
				:block="block"
				:content="block.content"
				:fetched-data="fetchedData"
				:static_content="block.static_content"
				:block_title="block.block_title"
			/>
		</div>
		<main-footer />
	</div>
</template>

<script>
import panels from '@/mixins/panels';
import createJivoMixin from '@/mixins/createJivoMixin';

import CenterHeader from '@/components/layouts/headers/CenterHeader.vue';
import PanelPartnersList from '@/components/dev/PanelPartnersList.vue';
import PanelReview from '@/components/panels/PanelMedia/PanelReview.vue';
import PanelDigitSteps from '@/components/static/PanelDigitSteps.vue';
import MainFooter from '@/components/layouts/footers/MainFooter.vue';
import makeCanonicalLink from '~/utils/seo/makeCanonicalLink';

export default {
	name: 'CenterPage',
	components: {
		CenterHeader,
		PanelPartnersList,
		PanelReview,
		PanelDigitSteps,
		MainFooter,
	},
	mixins: [panels, createJivoMixin('fetchedData.consultation_chat_code')],
	async asyncData({ app, params }) {
		const { $pagesApi } = app;
		const { slug } = params;
		const fetchedData = await $pagesApi.center(slug, app.i18n.locale);
		return { fetchedData };
	},
	data() {
		return {
			fetchedData: [],
		};
	},
	head() {
		return {
			title: this.fetchedData.meta_title,
			link: [
				{
					rel: 'canonical',
					hid: 'canonical',
					href: makeCanonicalLink(this.$route.path),
				},
			],
			meta: [
				{
					hid: 'robots',
					name: 'robots',
					content: this.fetchedData.meta_indexing,
				},
				{
					name: 'description',
					hid: 'description',
					content: this.fetchedData.meta_description,
				},
				// Open Graph
				{
					name: 'og:title',
					hid: 'og:title',
					content: this.fetchedData.meta_title,
				},
				{
					name: 'og:description',
					hid: 'og:description',
					content: this.fetchedData.meta_description,
				},
				{
					name: 'og:type',
					hid: 'og:type',
					content: 'website',
				},
				{
					name: 'og:image',
					hid: 'og:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},
				// Twitter Card
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:site',
					content: '@nuxt_js',
				},
				{
					name: 'twitter:title',
					content: this.fetchedData.meta_title,
				},
				{
					name: 'twitter:description',
					content: this.fetchedData.meta_description,
				},
				{
					name: 'twitter:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},

				{
					name: 'image',
					property: 'og:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},
				{
					name: 'author',
					content: 'SKOLKOVO',
				},
			],
		};
	},
	computed: {
		links() {
			if (!Array.isArray(this.fetchedData.children_links)) return [];
			const links = this.getLinks.map(({ name, slug }) => ({
				name,
				to: {
					name: this.$i18n.locale === 'ru' ? 'center-inner-page' : 'center-inner-page___en',
					params: { parentslug: this.fetchedData.slug.replace('-en', ''), slug },
				},
			}));
			links.unshift({ to: { name: this.$i18n.locale === 'ru' ? 'center' : 'center___en', params: { slug: this.fetchedData.slug.replace('-en', '') } } });
			return links;
		},
		getLinks() {
			return this.fetchedData.children_links.map((item) => {
				const container = {};
				container.name = item.name;
				container.slug = item.slug.replace('-en', '');
				return container;
			});
		},
	},
	created() {
		this.updateAlterVersion();
	},
	methods: {
		updateAlterVersion() {
			this.fetchedData.alternative_language_version = this.$i18n.locale === 'en' ? `/centres/${this.$route.params.slug}/` : `/en/centres/${this.$route.params.slug}/`;
			this.fetchedData.alternative_language = this.$i18n.locale === 'en' ? 'rus' : 'eng';
		},
	},
};
</script>
